import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import ForumBox from 'components/Software/Other_Platforms/Homematic_IP/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Homematic CCU3 Hub",
  "path": "/Software/Other_Platforms/Homematic_IP/",
  "dateChanged": "2019-02-15",
  "author": "Mike Polinowski",
  "excerpt": "In order to be able to control and configure your Homematic or Homematic IP devices such as your INSTAR IP camera and to use them in central programs, you must integrate the devices in the system via the WebUI.",
  "social": "/images/Search/AU_SearchThumb_Homematic.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Homematic_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Homematic"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Homematic CCU3 Hub' dateChanged='2019-02-15' author='Mike Polinowski' tag='INSTAR IP Camera' description='In order to be able to control and configure your Homematic or Homematic IP devices such as your INSTAR IP camera and to use them in central programs, you must integrate the devices in the system via the WebUI.' image='/images/Search/AU_SearchThumb_Homematic.png' twitter='/images/Search/AU_SearchThumb_Homematic.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/Homematic_IP/' locationFR='/fr/Software/Other_Platforms/Homematic_IP/' crumbLabel="Homematic" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "homematic-ccu3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#homematic-ccu3",
        "aria-label": "homematic ccu3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Homematic CCU3`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#time-scheduled-camera-operations"
        }}>{`Time-scheduled Camera Operations`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#installing-the-cux-daemon"
            }}>{`Installing the CUx Daemon`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#creating-a-virtual-button"
            }}>{`Creating a Virtual Button`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#creating-the-program"
            }}>{`Creating the Program`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-the-homematic-ccu3-as-alarmserver-for-our-camera"
        }}>{`Using the Homematic CCU3 as Alarmserver for our Camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#setting-up-a-system-variable"
            }}>{`Setting up a System Variable`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#configuring-the-alarmserver"
            }}>{`Configuring the Alarmserver`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#writing-the-program"
            }}>{`Writing the Program`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-our-camera-to-an-homematic-pir-sensor"
        }}>{`Connecting our Camera to an Homematic PIR Sensor`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`In this tutorial we are going to use the Homematic CCU3 to control an INSTAR Full HD camera through the Homematic WebUI. Note that there is another tutorial available that uses Node-RED via the Homematic RedMatic Plugin to basically do the same thing. You can `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Homematic_CCU3_and_RedMatic/"
      }}>{`find the RedMatic Tutorial here`}</a>{`.`}</p>
    <h2 {...{
      "id": "time-scheduled-camera-operations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#time-scheduled-camera-operations",
        "aria-label": "time scheduled camera operations permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Time-scheduled Camera Operations`}</h2>
    <p>{`In this part we want to create a time schedule to automatically send CGI commands to our camera at specific times of the day. What we want to achieve is an automation that activates the camera's motion detection after we left our home on a workday and deactivates it before we arrive back home.`}</p>
    <p>{`For this we are going to create a virtual button with CUx-D and write a small script in the Homematic UI that triggers this button when it is time to switch the alarm on / off and send the corresponding command to our camera.`}</p>
    <h3 {...{
      "id": "installing-the-cux-daemon",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#installing-the-cux-daemon",
        "aria-label": "installing the cux daemon permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Installing the CUx Daemon`}</h3>
    <p>{`We will use the `}<a parentName="p" {...{
        "href": "https://github.com/jens-maus/cuxd"
      }}>{`CuX Deamon`}</a>{` to create a virtual button that we can later attach our programmed logic to and send commands to our camera.`}</p>
    <p>{`We first have to download the `}<a parentName="p" {...{
        "href": "https://github.com/jens-maus/cuxd/releases"
      }}>{`newest release of CuxD`}</a>{` - at the moment of writing this is the:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://homematic-forum.de/forum/viewtopic.php?f=37&t=15298"
        }}>{`Version CUxD 2.2.0`}</a></li>
    </ul>
    <p>{`Choose the correct version for your CCU - in my case this is the CCU3:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "887px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/eac55/homematic_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABc0lEQVQoz42PTW/UMBCG9/dzRipSUX9CD1wQJwSHCmgqLWpKnMQfcRx7/RUn9jjLli4CpZUocIA+Gr23Z96ZjbNW9Uxz4qy13k8hpJQSQJrmFEKapzXDmEII3h2//3j39g1C9eFwm3PehN3utiwPRbG/uNh/+rgvLperq1wUebsFQoASqGuoG0AI+PB1sa/Pn314f/7t7giQNs5agVBfXu/Ka7HdDjc3gfNFSghh7f+dvJ8NZp9feV3FmNZmY82XCpVV1VBS1nXVtkprP03jGCBBBsg5A0CMMWdQSqOGaTOO47jK3jkl5TAMDzkIIaUUQshBeu+NtVpr731KKcb1FIAIKcYYl2XZGGNw22KMm6ahjPV9zzkXQmCMOefOOWPWovQnjzJCNaoQbnHXdVopb8w8TSGEeZ7zPfDX879ka213DyWEsc4ty3y8A8jpnzzKhBCC8TqMqucn44uXMUzxiTKllDG6rqBUnp6p0zOr9Rzjf+WfG98grOFq3uUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/e4706/homematic_01.avif 230w", "/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/d1af7/homematic_01.avif 460w", "/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/abb2d/homematic_01.avif 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/a0b58/homematic_01.webp 230w", "/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/bc10c/homematic_01.webp 460w", "/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/1938b/homematic_01.webp 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/81c8e/homematic_01.png 230w", "/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/08a84/homematic_01.png 460w", "/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/eac55/homematic_01.png 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/eb3e0d5846b9f5f0290b7ede50e3f6d8/eac55/homematic_01.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we can start installing CuxD on our Homematic CCU3. Go to `}<em parentName="p">{`Settings/System Settings`}</em>{` and click on `}<strong parentName="p">{`Addons`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "916px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ae19568ad1926ac8b4e6c438490cd7d1/59822/homematic_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACE0lEQVQoz23KSWsTUQAA4PkD/gCv9VIQEaR4iOhRYy6SqBHUUio9KJZ60UJcYqExplBIG1QQAwZUqOhZK4jWpNU4k9hMZpK0b15mebNlmWT2JI41ET37nT9sbOKWLxA7ePzO2MT8Uf/iuC8y7ru9/9CNfQeuHzk2l0y9Wl59sfDgSWL5aTrzJvlobWYuNXV1aXb+sf98HAuEFy5cWZqeTYanH16cSYSm4sHJ2JlLi6fPRsPX7idW0qupdCSaPBmMBC/HQpNx/7l7gXD0VOju4RM3sTxBbWwWNnLE90I5u7Wd+1bCC2WiSBFFausruf4h/zlbeP8Rz6zlMq9zL99u4j8qeJHOE+VP2W3MdR2OhTu1mt5pA2Q5/b2O1oSQEREnd0zRHA16NoS7PLtr6g2h6dq9PQlxDAN4FmCmabD/yLLEK4bb9xoNlQEMh5DBC4Nnz/UvOU6WWAibDVVQDNv9GwADBIQwQzcghPV6XRLFuqg7PU+WRLJUqkFoQOjFlzrv1jlFqTNMQ1VYSbddT1FkkiQZhsEMwyBLJRzHBYGrsZppD5DAEwRRq1ZYuQ31oWkZlUqVpmkR8TRs6VZfRAJF0QAAzHEcSZIQQu12S1BMt+d1tDZCSFWVRqvbbLneoK9pHU3TLMsy7J/er9+2ZWmapus61u12q9UqRVEiEkjQ0q0BQny5TAGww/IqQPZoNBoOR//1B4fholCvDlXlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae19568ad1926ac8b4e6c438490cd7d1/e4706/homematic_02.avif 230w", "/en/static/ae19568ad1926ac8b4e6c438490cd7d1/d1af7/homematic_02.avif 460w", "/en/static/ae19568ad1926ac8b4e6c438490cd7d1/1e879/homematic_02.avif 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ae19568ad1926ac8b4e6c438490cd7d1/a0b58/homematic_02.webp 230w", "/en/static/ae19568ad1926ac8b4e6c438490cd7d1/bc10c/homematic_02.webp 460w", "/en/static/ae19568ad1926ac8b4e6c438490cd7d1/3fccf/homematic_02.webp 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae19568ad1926ac8b4e6c438490cd7d1/81c8e/homematic_02.png 230w", "/en/static/ae19568ad1926ac8b4e6c438490cd7d1/08a84/homematic_02.png 460w", "/en/static/ae19568ad1926ac8b4e6c438490cd7d1/59822/homematic_02.png 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ae19568ad1926ac8b4e6c438490cd7d1/59822/homematic_02.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now select the file you downloaded and click on `}<strong parentName="p">{`Installation`}</strong>{` - once the file is uploaded click on `}<strong parentName="p">{`Start Installation`}</strong>{`, wait for a few minutes and reload the Homematic UI once the CCU is back online:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6c3cee389e3ffaff886ccd484fe38624/4255a/homematic_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADCUlEQVQoz1WQ22/TdhzF/V8xpDGJbTAeNnUPTEJi2r+wd7ZHpDJGmQSBotA0ZbSUcGmJQ9OyOKR2kiYmdbLYSRpfEv98v8S/+JI0OBcUlL5MOzo6+r4cfXS+SCSGbSSKjzeJSCy7tok/XM8+WM/effRu+V76UTxNVhiyQhOFCp7/cEw1imX6xZvC8x18d+/oSQJHXiaJ12h2B8V23uYO89XDfC1HVDO5ynu8VqKYZptlmBZVrR/mK4UjKl+s/fO+ksWr6H4xtpVFdMNe307ceriK4TnT0HVdCwb+ZBwGgdcVOpIk+b5fr9PsmXiOE3gOwp6ma4YFkcFg8PjZ85uR1XyZ7PCCLCsAAI7jNF2nalWWZSeTSftEVbVTyxzbVjgahR/DUNcNCB3E9/1I/Mnvd/5KZzCe43lBUBRFVVXTtIQFWbYdRwQShJ4XDF0v8IPA830RgH6/jwyCYG07sbwazZdJRZJ1Q1cVVVFkTdNIkqQbzGz2qUwQeDLJYlhuL42m3gIALMuGECJBEETif/92eyWNZdsn7TO20Gw1ZUU5abc7na7dc1iWA10R8IIkihzfUTVDBMBxnMXm+ItXf0ZjRKlk6LphWtPpdD6fnw6HEpAs05xOJ7bjcYLa0VzXtlQgq1bg+950Ol2Q769v3Li9ksnhPMdxghAMFgrCj6eTyWg2c1yXYRqqpkHY7zkOhNBxoGlZ8/kcCcPx1m7yTjRG0bTrutCBrmmqkuR8qMDj416h6HI83Wq2Wq1Gg6nT9L/1Os0wkiQvyuNx+OwNurIWp+r12Xx+qurDlXuwQmk83yAIcEwZXZHvdhc/lGRRFIEoGoZp271FudYUn77evRuNkrXqKBz5vutDGHiuPxy4g8AfDften2XbgsAKHY7nWZ7nPA8CWW2xGvLH8nbi6d7LzWTqFZbZK6TSR+i7MnpQQtNHqf1Fovul1EE5dVBKnR3ofimdITe2sRs3t5Bff7j683dLv1z58do33/90/uuLFy6d++ry+f/7iwv/+dyXly5eXlq6ev3bK0ufARN4lHZmPFTfAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c3cee389e3ffaff886ccd484fe38624/e4706/homematic_03.avif 230w", "/en/static/6c3cee389e3ffaff886ccd484fe38624/d1af7/homematic_03.avif 460w", "/en/static/6c3cee389e3ffaff886ccd484fe38624/6e770/homematic_03.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6c3cee389e3ffaff886ccd484fe38624/a0b58/homematic_03.webp 230w", "/en/static/6c3cee389e3ffaff886ccd484fe38624/bc10c/homematic_03.webp 460w", "/en/static/6c3cee389e3ffaff886ccd484fe38624/632b0/homematic_03.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6c3cee389e3ffaff886ccd484fe38624/81c8e/homematic_03.png 230w", "/en/static/6c3cee389e3ffaff886ccd484fe38624/08a84/homematic_03.png 460w", "/en/static/6c3cee389e3ffaff886ccd484fe38624/4255a/homematic_03.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6c3cee389e3ffaff886ccd484fe38624/4255a/homematic_03.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "creating-a-virtual-button",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-a-virtual-button",
        "aria-label": "creating a virtual button permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating a Virtual Button`}</h3>
    <p>{`Once back on the Homematic UI return to `}<em parentName="p">{`Settings/System Settings`}</em>{` and click on the `}<strong parentName="p">{`CUx-Daemon`}</strong>{` button:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/38c952142ea04d53df6eaef099c09607/4255a/homematic_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACGklEQVQoz03PyWsTYQAF8PwJXr3aiwXRWz1UFDyIVktAuti6oK2oh1QEsUqpWlpDSUBNUapYEYSKKMWDBxH0ohiTNEmXzExmsTPfbN9s38wkk9lirBlJT77j4/0OL9bVM3noVHLf4emunsmDx+e6e6e6e6d277+xa09ib9/EwtLbzJPlB+nn6UcvXr1eySy+G0tkLlxLJW49PTaQjMVHZ0fGU5evPx4emz93JT10aX7wYvL0+bmTg/eHJ2ZSmZeZhaXb0w+Pxu/0j8zGzyb7hu71n5k5MXD3wJGbsUIJ+55dz61ipTU8X6xk85urZaxQJtY3q7l85fOXwrfsxqevpTcf8ssr2fcfc+UNorhG/CziP/J4zDZ1VnIcf7tuI5bdkkSg6qagt5qhxzAUw5C6KvKq6zXbNUsR+a1fDCXJKlCbURTFZFniYc3xWsjQGZoBHKdqSDbCIPABACzLqQoUlLrj/zFNozMAQNWQqHkdLEkikO2G19I1BcMwmqYUzZCMIPA9juNYllWgzEKn1vhtGBqGVRiGVjUkqDuYByzFIdtpKlAulopEleBFhZVdz3MpiqriOIAwXHwWjF/lqyRBdTqOl0lQ72CEdFGtN/xWzbZEUYQQ6shSzDAMAwihJEnIRIgGfpXxXdeyLNu2G67vBts7nyUBY3Sz3lQVuYLhNE0CAdKi67kNkiQxDIeSsMnXkfc3iqJ2O/o//wDOHKTPPlkSEAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/38c952142ea04d53df6eaef099c09607/e4706/homematic_04.avif 230w", "/en/static/38c952142ea04d53df6eaef099c09607/d1af7/homematic_04.avif 460w", "/en/static/38c952142ea04d53df6eaef099c09607/6e770/homematic_04.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/38c952142ea04d53df6eaef099c09607/a0b58/homematic_04.webp 230w", "/en/static/38c952142ea04d53df6eaef099c09607/bc10c/homematic_04.webp 460w", "/en/static/38c952142ea04d53df6eaef099c09607/632b0/homematic_04.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/38c952142ea04d53df6eaef099c09607/81c8e/homematic_04.png 230w", "/en/static/38c952142ea04d53df6eaef099c09607/08a84/homematic_04.png 460w", "/en/static/38c952142ea04d53df6eaef099c09607/4255a/homematic_04.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/38c952142ea04d53df6eaef099c09607/4255a/homematic_04.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Devices`}</strong>{` and select:`}</p>
    <ul>
      <li parentName="ul">{`Type: `}<strong parentName="li">{`System`}</strong></li>
      <li parentName="ul">{`Function: `}<strong parentName="li">{`Exec`}</strong></li>
      <li parentName="ul">{`Serial #: `}<em parentName="li">{`leave at default`}</em></li>
      <li parentName="ul">{`Name: `}<em parentName="li">{`Choose a name`}</em></li>
      <li parentName="ul">{`Icon: `}<em parentName="li">{`Choose an icon`}</em></li>
      <li parentName="ul">{`Control: `}<strong parentName="li">{`Button`}</strong></li>
    </ul>
    <p>{`Click on `}<strong parentName="p">{`Create Device`}</strong>{` to save your settings:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "916px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4dcee67c391ae900b319e422a5a8fbd6/59822/homematic_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAACJUlEQVQoz32S22vUQBSH9//3xReffOuDD0UUi4KlKggWLULbtbWl3V7czWZ3c5tsbjOTZGY+SbK9UNCBHydkZr5zzm/OyLkE5wTOpRsJpFxSFHOUWnH57T0ne2853X3N+MMrJt8/k2UrZLkAatT2FtXzZ4DGmYiRMQl1PSUMx2TZKcYEZNk5vn+MlDfU6oZg+QMRHlCur5mcj/k1PmQ2m2BtQv1lh2b3DRaBMTGj7qdzMdaGvSDB2oC2XeFcSFEIwihmGadksgAKLGtsF10GVIgyRiQejoQRCJomRKklWq+QckXTdEkyjBFYl2LqiGB/h/jgHer3HtXpHvL4I60Y47ilqS9p2oCONYKUsvAIVhfE8RQhlkgZYW1G24r+UOeNmh3RBido/5jKO0ZNDzH5NbDAWh+t4weglD5hOKEsI4wpybIpVTUnz2e0bYgjxfGP5cBa0Hrd29UDq9Jj7k+4ur3EW8xI1jFNm2BMRLffZTZtDc7i7CM52xOttWidboA2psw9fP+CNJ0hxJS6SXr/YE2e+9R1hHNmKMg91Hr3ba17BMSgbMLcuyCKfaSKMG1K08T9i56dHZGmXnetBzzVUOEjoN7eQv78yloGiHhGni9o+1bFZuCTjSz/W/ceqpcvkPufWKuQtZiSJNO+xQGY3IO1zlFqUF0XtG2F1gVKZX3UOrlrue2Hs7s4PED6BDbEzt/l8qpXl1SpgDj6w3w+jFvTDEX8BRE1lGWL6ccHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4dcee67c391ae900b319e422a5a8fbd6/e4706/homematic_05.avif 230w", "/en/static/4dcee67c391ae900b319e422a5a8fbd6/d1af7/homematic_05.avif 460w", "/en/static/4dcee67c391ae900b319e422a5a8fbd6/1e879/homematic_05.avif 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4dcee67c391ae900b319e422a5a8fbd6/a0b58/homematic_05.webp 230w", "/en/static/4dcee67c391ae900b319e422a5a8fbd6/bc10c/homematic_05.webp 460w", "/en/static/4dcee67c391ae900b319e422a5a8fbd6/3fccf/homematic_05.webp 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4dcee67c391ae900b319e422a5a8fbd6/81c8e/homematic_05.png 230w", "/en/static/4dcee67c391ae900b319e422a5a8fbd6/08a84/homematic_05.png 460w", "/en/static/4dcee67c391ae900b319e422a5a8fbd6/59822/homematic_05.png 916w"],
              "sizes": "(max-width: 916px) 100vw, 916px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4dcee67c391ae900b319e422a5a8fbd6/59822/homematic_05.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can address the button from the example above by calling `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CUX2801001:1`}</code>{` as the assigned serial number - `}<strong parentName="p">{`in my case`}</strong>{` - was `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CUX2801001`}</code>{` represents the type, function, etc. that we selected for our button). In the next step we will write a small program that assigns a series Linux commands to our button that will be executed (`}<strong parentName="p">{`EXEC`}</strong>{`) every time the button is used.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "creating-the-program",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#creating-the-program",
        "aria-label": "creating the program permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Creating the Program`}</h3>
    <p>{`We now want to write a program that will deactivate the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Schedule/"
      }}>{`alarm schedule of our camera`}</a>{`, when we are at home and activates it again after we left for work. To do this we will assign a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`wget`}</code>{` command to the virtual button we created above. This command will contact our camera every time the button is triggered and use the `}<a parentName="p" {...{
        "href": "/en/1080p_Series_CGI_List/Alarm_Menu/Schedule/"
      }}>{`CGI Command to switch on the Alarm Schedule`}</a>{`.`}</p>
    <p>{`Navigate to `}<strong parentName="p">{`Program Menu`}</strong>{` and click on `}<strong parentName="p">{`NEW`}</strong>{` to create the new program:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "887px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ad744c434ae250b04fe30315a6ade5ef/eac55/homematic_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACqklEQVQozwGfAmD9ACk8ejFCfhUpbS5AfSw9eyI0dh8xcxAlaxgmZ2ZojYiQqHJ6lHJ6k36Em4+VqnZ/nHJ7mWZvjXeAnFNfigBTX4xYZJFSXo1VYY5ga5VRX4xIVoc7SoA9UHiKlpuoqbCXmZ+Vlpxvdo2CiZ9jbI9IVYJIVIBGUoA0Q3kAi5KjhYyfkJWkkZekj5Sjm5yipKWlo6Sko6SmrbCzqaqwlZijiZGiW2mTUF+ObHeYkJalkJWmiY+if4ehAEV+xG6Lpp2nqIuboIqQlJ16fZ18f517fpp4fJ9/gp97fZuWmpKjp4+cnJCdnJCeoJGfpI6doIWVmK+4uQCJnribpa+xtLOhp6iIlJWPl5mQlpmPlpiNlJaLkZOGjI+OmZuQnJ6JlZiEkJKKlZiOmpqPmpuKlpiss7QA3drW393c7u3t3t3dzs/P0tPT1dXV0dLS3d/f5eblxsjIyMnI1tbW3t3excXFs7O0xcXF0NDQ4+Pk4uLiAODh4dzb3Ovr69vc28/Q0NfY2NbW1dPS0t7d3uLi4sjIycvLy9LR0dzc3MnJycDBwcnJyc3NzOPi4uLi4gDj4+Tf3+Dv8PDe4ODOz9DS0tPW1tbf39/h4eLf3+DOz8/f4ODh4eHd3d7IyMi7u7zKysvT0tPm5ubj5OQA3t3d39zb7vr355SW04qN1N/e1dLS2trZ4uLh5eXkysnIz8/O3d3c6Ofmy8rIt7e3y8vI19XS7Ovo7OvoALK2wbO1wbLD0tFpdMFseK/B0Lq8x7i8yLO4xbK3wqWqtaqvurO3w7K2xJ2hrpebppOZq5CXrJuit52kuQCLkKSEi6JqdZeSl6qJkahrdZZ3fZFwd49tdpaBh550eo9scoiAhptye5hxeZaVmqs7Sn0HHGQPI2cOImdocaoJZ6waegAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad744c434ae250b04fe30315a6ade5ef/e4706/homematic_06.avif 230w", "/en/static/ad744c434ae250b04fe30315a6ade5ef/d1af7/homematic_06.avif 460w", "/en/static/ad744c434ae250b04fe30315a6ade5ef/abb2d/homematic_06.avif 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ad744c434ae250b04fe30315a6ade5ef/a0b58/homematic_06.webp 230w", "/en/static/ad744c434ae250b04fe30315a6ade5ef/bc10c/homematic_06.webp 460w", "/en/static/ad744c434ae250b04fe30315a6ade5ef/1938b/homematic_06.webp 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad744c434ae250b04fe30315a6ade5ef/81c8e/homematic_06.png 230w", "/en/static/ad744c434ae250b04fe30315a6ade5ef/08a84/homematic_06.png 460w", "/en/static/ad744c434ae250b04fe30315a6ade5ef/eac55/homematic_06.png 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ad744c434ae250b04fe30315a6ade5ef/eac55/homematic_06.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose `}<strong parentName="p">{`Time Control`}</strong>{` for the `}<strong parentName="p">{`IF Condition`}</strong>{` of our program and set the time window when you are not at home. In the example I choose a window from `}<em parentName="p">{`8:30 am to 6:30 pm`}</em>{` only on regular `}<em parentName="p">{`workdays`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "887px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9235d3a25cfa384701d3369613f64a1/eac55/homematic_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACaElEQVQoz3WS22+bMBjF+fMnbdrL1GnawyZtD7tJ20O1dc3aZlHTJF0JBJIsDaRNCGAwNxsCwcaAJ5q2Wh/6k+WHIx99l2Ph+avDvTfHB61Bpyu2u6Ioz0aqJilzWdFkVZcVTVI0WdFGqi4p2nB0Kav6hTTb/9n/st8X3n5of/zanUznrmNBF9i2BYDtOLZtmxA6tm0Caw2h60InCoNNgkmeZdvs87fO+0+/hYE47g7k6WzueR5COI6TIAjCG6IoghCawINegDFO03S73eZ5jhDunA1P+4ow0/ULVUUYMcbKsuScoygyDANjnGVZWZYZYbRgvK75HUVRXCjKbKELQRhCz0vimBa0qqu6rk1zvVjo0HU3aXpvqO/YmR3XjSIkTGazwVBaGQaEsGCsqqosa9rbPa2qqqweUNc1IWSqLbSra0FfLYfTyXptAgAIpZzzJElwHBOS/9fpA4qCaUtjsVwJE+1SVMemZTkA5DnhnGOMA9+PEKofcdOi+KstFitDmGpz9XLuNmm4RVFwzkmeJ3FSPVaXc0qL6c4sTcZ9UbpeLgFw0ixjZbPz22nLkjUZ3CjVrVJVVTOzfqVdLwU38BzP8zwfI4xQRGlBCLkvkqZZGIZxnFBKC8Z2ImMMuDCIkLCLIUmSOI7Ths1mk+T5lpCcEhIE3mq1xBghFPk+zLINY5TSvKqaHyGEaIPjdKRMWkftkTKRR+Oz3vnKsIIQ+37kwhDC0A+Q40aW7Zu2B0BzWyCAfiy02sPeudLpKe1TVZRm3cG4dSIenfR/HQ/anT+Hx2LnVOoN1Nfvjp+8+P7s5cHTvR/35x/cfflB4HDJagAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9235d3a25cfa384701d3369613f64a1/e4706/homematic_07.avif 230w", "/en/static/a9235d3a25cfa384701d3369613f64a1/d1af7/homematic_07.avif 460w", "/en/static/a9235d3a25cfa384701d3369613f64a1/abb2d/homematic_07.avif 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9235d3a25cfa384701d3369613f64a1/a0b58/homematic_07.webp 230w", "/en/static/a9235d3a25cfa384701d3369613f64a1/bc10c/homematic_07.webp 460w", "/en/static/a9235d3a25cfa384701d3369613f64a1/1938b/homematic_07.webp 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9235d3a25cfa384701d3369613f64a1/81c8e/homematic_07.png 230w", "/en/static/a9235d3a25cfa384701d3369613f64a1/08a84/homematic_07.png 460w", "/en/static/a9235d3a25cfa384701d3369613f64a1/eac55/homematic_07.png 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9235d3a25cfa384701d3369613f64a1/eac55/homematic_07.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now we have to write the action we want to trigger when the `}<strong parentName="p">{`THEN Condition`}</strong>{` is met. Click on the green cross and select `}<strong parentName="p">{`Script`}</strong>{` from the drop-down menu. Click on the three dots that appear next to it to add your script:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "913px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5dc7fca5946ff14891a41edaa1e89026/0eb6d/homematic_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.60869565217391%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACc0lEQVQozy3NW0/TYAAG4P4D/wCXXmo0xgvuSDSa4I2JijcYCEFJQBAxGOOZCQSRhYFdd6IZ6/mwY/v1+9axQ9t10O4IMlYFhd9iQJP34nnfmxe7eWehf3Cx7/qr/ntLN25/ujrw4crA+75rc5cuT94afxuMpb+skJ+XQour0VBM+hrgZt5Exmf806/xpy9w7MlEYGwaH53cfDYbnJqPPJ+PTM1HHo2uDj5enPKtJegkRfO+5dDQiG9iNjAyuX5/ePnhyMrgkO/ugwUsEGLW8AQeZTfC1BqeWCeojTBNkDxBcv7v9JI/7se3P67Ex14S43PEzLsYscXjMWYjzOJRAYvL6QjD0elslBNCNBtmhRDDkaJMpTLbqTQpihFOICVxi2e4XAqUd+JSiqAYOpPjsznMcev7Bz9Oz856nnfY7faOvJ7n9X56juO0252e5x31eofdbqvd+XV88vvP6fHJyf7BQavT6XT2scg2HUswtJyWAZQBSmp5GSAZoDQsJDUkASSqmqhqXFbNaHndtKFRhRUrb9ZUfQfbyiUTGTVXsZSSoZZNpWQqRUMpGqBSVUoGMGxo1aBV00xbt2plp1l2WyWnUdxrKIUSFsSDbJxSAZJRQVA0SUPSxaGk5eV/AEhUoKhC8XxEXA4koQ4qVk7fwdjNILdJFL+tN4fHEmRCKZSgYWuVKjRsULY0owpNG1SqmmFDw0bWLiibyNqt1NvIqGKMKLFikqR5keKSmi6AvKAiEeQFBUpA5xUoqEgCuqAi4cIyLIggz2RVGSCs7Dp2s2M4davZtt2m5TRMp2Gdp265DXOvfuGG6dT/221W3fO622j9BfZgryFDejPRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5dc7fca5946ff14891a41edaa1e89026/e4706/homematic_08.avif 230w", "/en/static/5dc7fca5946ff14891a41edaa1e89026/d1af7/homematic_08.avif 460w", "/en/static/5dc7fca5946ff14891a41edaa1e89026/90e2f/homematic_08.avif 913w"],
              "sizes": "(max-width: 913px) 100vw, 913px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5dc7fca5946ff14891a41edaa1e89026/a0b58/homematic_08.webp 230w", "/en/static/5dc7fca5946ff14891a41edaa1e89026/bc10c/homematic_08.webp 460w", "/en/static/5dc7fca5946ff14891a41edaa1e89026/5ffe7/homematic_08.webp 913w"],
              "sizes": "(max-width: 913px) 100vw, 913px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5dc7fca5946ff14891a41edaa1e89026/81c8e/homematic_08.png 230w", "/en/static/5dc7fca5946ff14891a41edaa1e89026/08a84/homematic_08.png 460w", "/en/static/5dc7fca5946ff14891a41edaa1e89026/0eb6d/homematic_08.png 913w"],
              "sizes": "(max-width: 913px) 100vw, 913px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5dc7fca5946ff14891a41edaa1e89026/0eb6d/homematic_08.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This will open the script editor. Now copy the following script block and paste it into the editor. `}<strong parentName="p">{`You will have to adjust the CGI command according to your camera setup`}</strong>{` - in the example I used an `}<em parentName="p">{`IN-8015 Full HD`}</em>{` on the IP Address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.117`}</code>{` and has a administrator login with username `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin`}</code>{` and password `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`WriteLine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Motion Detection Armed"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.117/param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "917px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7d56d5efff266309822c56a366b08e0a/59000/homematic_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAA7CAAAOwgEVKEqAAAACXElEQVQ4y6WS32/SUBTH+5/7rI/6aKJP7kUz49CYxQ02x0RBJ7QMCmNSSltKW6A/YRTox9wOt2UPi8aTfPI95/aec8/tudLT5/t8LOxTPChy/PmUypcqXytVZLmF0u6itLo0z9UcsSY3VeRmm8ZPhUa9Sf1MRq4r1GWVlzslpNdvjzgrllArFZRymcZRiXrxEPnoiPpJmcbJCcppGUcfMLVHpGnKKl2yTGLS+ZyrOGK1mDPzQ168KiJ9+1Gn+umAxsEhX98VqH34QK3wnupegWque9T291kulwjbbDZkWcZ9mwUBrc4lUqfXo6+qzEwT51cfV9Nw+33cvnbtCwYDkjAkDkMi3+dqW/yuxUnC2HWQLno9jNGIqzQlns9JFguSxdVWb/0ojm8Io4gwivMiIhbquB6u6yGdyQpqt8vEm+A4DuPxGNu2cxXxH991XRzXxfU8RraNaVk3xdfrNbph0utrSA21ReeiS+D7TCcTPJHoOFiWhed5eaHJZJLj+z5RFOXaarU4bzbpdDr5lUWHl9oAqVb/SVvt5AV0XccwTUzTZGgYOSI2DAN9OLyJtcGAga4zm/n5QUEQcKlpaEMD6YciYztOPjnxJNLVKme15a5/nzRd5Tli8lPfz2chNdrnTKZT/tfE/7TsMVIQhsx8//qNrddkgiz7J4SJjpfLFEm0HMXJzUni8ybL2GyyW32I7Z7rTJAs20VV2xj2CLf2naBYIg4D4iQmjqO/YrFIGNljev0R0m6hTOm4Qq15QXNnl4vHz9A0i4HhoOljtOHD9HUby57yuaLw6MkbfgPj0xNHEwagFwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d56d5efff266309822c56a366b08e0a/e4706/homematic_09.avif 230w", "/en/static/7d56d5efff266309822c56a366b08e0a/d1af7/homematic_09.avif 460w", "/en/static/7d56d5efff266309822c56a366b08e0a/bea5c/homematic_09.avif 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7d56d5efff266309822c56a366b08e0a/a0b58/homematic_09.webp 230w", "/en/static/7d56d5efff266309822c56a366b08e0a/bc10c/homematic_09.webp 460w", "/en/static/7d56d5efff266309822c56a366b08e0a/f91b9/homematic_09.webp 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7d56d5efff266309822c56a366b08e0a/81c8e/homematic_09.png 230w", "/en/static/7d56d5efff266309822c56a366b08e0a/08a84/homematic_09.png 460w", "/en/static/7d56d5efff266309822c56a366b08e0a/59000/homematic_09.png 917w"],
              "sizes": "(max-width: 917px) 100vw, 917px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7d56d5efff266309822c56a366b08e0a/59000/homematic_09.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`In this CGI command `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`week0`}</code>{` - `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`week6`}</code>{` represent the days of the week Mo - Sun and every `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`P`}</code>{` stands for 30min of the day (`}<em parentName="p">{`48 x 30min = 24h`}</em>{`) where the alarm on your camera is active. You can customize this schedule by replacing some of the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`P`}</code>{` with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`N`}</code>{`. Every `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`N`}</code>{` will deactivate the alarm for 30mins during that time of the day.`}</p>
    </blockquote>
    <p><strong parentName="p">{`OPTIONAL`}</strong>{`: You can test your script by opening the Homematic WebUI a second time in another browser tab, going to the `}<strong parentName="p">{`Program Menu`}</strong>{` and instead of adding a `}<strong parentName="p">{`NEW`}</strong>{` program, click on `}<strong parentName="p">{`Test Script`}</strong>{` and copy your script into the input field:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ae84fb421d47b41bd83d8d2e875a9752/4255a/homematic_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "109.13043478260872%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAEaklEQVQ4y42Qa0xbZRzGT9SoS4QlSmI0i9OJ2jaMOwFxX/ywJRDcGFCKLYwKCZdBWwoEmMsGso4CMolkJhJ02dicyE0y6AYFKeuN6yltKb0XWloKtZfTnl5ooT3HFPQTmdkvz4f/+ybP8/zfF+juZff2z3X3snv6pn959Lynb7r756n2HlZbN6vnAUuwJBUIxXO8ZS4fXFqRCRbWWOzliUnhFGd1dPgZMDDCHR0XjrEW/njCH2cvjbCEQ+PCx6Pch0PPh9hzy2IpuCrlCZfHJ7nsucXJvxb7BzmPhzj9Q9z790YB/rKcMy9dEskWp6bUQqFVpXLotJBOC+s34Q29Vamxa3U2tc68rjKtKS0KtRpcXRLJllYVCzw+MCwwDnJ1i2Lx2sDg9uyseX5+mycwcnmOFRCWrcNKpUsq21NpvAqFR6XcU6s357j8FZC7AkpEC8CG3qA3GDf1euvuLnpwgAT20f2wkEAg5A+EAoGg3x8KHA6BABoM6jRaUCKVa3RqmQiw2+0w7HK73S6XC30JLBbLjtns2/PbtzcBq9Vqs1mdTicEQQcHB8FgEEXR0AtAEGTbZDIat1xuz99GXbgZgiAYhjVajVartdlsHo/X/2K2tgzb2ybY47UadYDzENjlslgsXq/X4/Hs+f0oiiLHCIVCKIru7uyYjMZ/zTarFbLbnQ6Hy+n8/9ciCIKiqNlsNm79t7bH4/H5fN5AAIKgo/ijhuPOo3ubzQZBkM+/D+0YABRFUL//QKmCYfhlftvtdiNIOMXr2AWCsAvOKXBGnTL8em9tQ7cmFptMJp/Pt7fn8x7i8/ncbrfeoJdIJXKlwmjaOkoJmxVyw9Zvw+aGFimbLwTl05wVwYJULNWAkg2JTAeKlCJQLlVsLk9w1sYmpCNPBu6zH42JHo6sDPw+BVDqWqg3Oq9ea6M23aqtb6lvZFDpNyqqGiuqmqpp1ysp176hfHu19iatsr6+nE4vpZCLa4pKGyqorRWVjQAGh4uJiY1LTj+b+Dk2IS02JR0bn3IGGxeNi/8QE/vJZ2fPn/4oLhrzweERk5gWl3ruDCYmPjEhNj4eiIvBnssoIP8kyevgF3QJs5n8zFu8LAYvi8H9qgssufnM8f7pO8XMCz3ruW2Cr7vB8r51wu3Jj7EJn0ZHA1gsJin1i+rrXQ2Muw2Mu7Xf/Vh3pObuS2UNzEwSEnWKlXo+u6Quh0y7XEzNKaGTiilp7773dlQUgMPhkhITykrJ1KoKSlU5paqCTqPW0Cg11Go8Ib80+xInKb31QkYOAV+AzyXg8/D5+UVZmV+++caJiIiwOTk5mUAoIBAKLmZl5JBp+NYJfPMIvvlPEmOc0PY0j8nCM5+SbrPCYowXds7kFlHeef3ViMiTh81JSUQi8UoxOT83m1DeVNglKOyYLezkkDpmw2qfLTwaOmaJ7TNFPwizyfSIt06EzRgMJiUlhUgkkkiFeZcvEsoaSd9zScxpUvvMcRGZ7KI7/EtXaK+9AkRGnvwHG7GbnZ3dFFIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae84fb421d47b41bd83d8d2e875a9752/e4706/homematic_10.avif 230w", "/en/static/ae84fb421d47b41bd83d8d2e875a9752/d1af7/homematic_10.avif 460w", "/en/static/ae84fb421d47b41bd83d8d2e875a9752/6e770/homematic_10.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ae84fb421d47b41bd83d8d2e875a9752/a0b58/homematic_10.webp 230w", "/en/static/ae84fb421d47b41bd83d8d2e875a9752/bc10c/homematic_10.webp 460w", "/en/static/ae84fb421d47b41bd83d8d2e875a9752/632b0/homematic_10.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae84fb421d47b41bd83d8d2e875a9752/81c8e/homematic_10.png 230w", "/en/static/ae84fb421d47b41bd83d8d2e875a9752/08a84/homematic_10.png 460w", "/en/static/ae84fb421d47b41bd83d8d2e875a9752/4255a/homematic_10.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ae84fb421d47b41bd83d8d2e875a9752/4255a/homematic_10.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Executing the script will give you the command line output `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Motion Detection Disarmed`}</code>{` and switch on the alarm schedule on your camera. Replace all the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`P`}</code>{`s in the CGI command with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`N`}</code>{`s and try it again - now the schedule will be deactivated. Success!`}</p>
    <p>{`Now as the `}<strong parentName="p">{`ELSE Condition`}</strong>{` we just have to add the `}<em parentName="p">{`deactivate the alarm schedule`}</em>{` CGI command and our program is ready:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`WriteLine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Motion Detection Disarmed"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.117/param.cgi?cmd=setscheduleex&-ename=md&-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "915px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/47ccb4621668c2f7c46dec7692e60c03/4255a/homematic_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC8klEQVQoz6XIzU/TABjH8f4P3jzoxUSjUU8mSuLFqIlRE6MxviYGRKPElwiiImqIIDAgzAkDXddtjL30/WWlXV823raVrhuwWQYy1nWgzogKGr1rJifPPvnkyTc/YPuBR4dOW/YdbdlR1XT4bNf+Y8+rjrftOti0adud3afu2qDgq/7h1g57m8XucMFWO3z5Rs+5mrart3uPnGkFLtZaqut6bzXar9+13WsarGuw1z9+fbO+71Ktpfa+xWJ12weHn7UOHjndcuJ8+8kLHeeqOy9d6z57paPqWDMA+tA+yGdzeB1e2PrGbX0zZAO9A54AFMAcQ0j3gKd7wG3p9zR3gs2dzs5+n2MY7oe8drcPZ2VAHB9nRIkRRGliEqEZjB3BOY7keYoPk+EwIwokx1M8R3EsK/LR2AQdFnF2hAoLs7oOTMYS0dFxVdP0hXfTmWxWz2XncnpuXktPK0lNzy38NZ/R56Zns4qayuq5mUxWUbV8wQA8GNYLOm2gGwwgboRwITgEY84A6sEoD0ZCMO6qjIQTxnwYRctjpDhKy2PwiBDX0gAu8xCKUkI0omhSXJUSqpxIyomkGK+EFFeFSaUiNrUxCjFFjE2FIhPpzFsADAatTo8LI70E7QxiDj8CwigYRFwoAQZQMIBAKAEhhCOI2of8fpwKhHhCGqVHJ7W3c4BE4TxGMn6E9sMiTkdpViJomWQiNCuRjPxXhApJJMMhxFiIi4elhBAZC/H5mQwwEWIwlguiBMNy8biSSqUVRU2qSU1LpdLpqSk1mdTS0zOCHGVGwvpsxiwtG+byu/mFH4tLQHku+3XJ+F4wf5rL64Xit3xhbcnY+BV5YyO+Lha+LBbWl4rfDXPdKK3njV8rH4Df/3FA6f1q+fOa+X515eOXSqysFkrlgvmhuFw2SuXiyudPq2t585OWKaay5j8yReDBk8Hn7Y77za9bXrh7bP6HT8GGplf3GrsaHvY0Pu6rq3/Z3uW8UNOxeWfd1r23tuz5xx+1a0diL17e6AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47ccb4621668c2f7c46dec7692e60c03/e4706/homematic_11.avif 230w", "/en/static/47ccb4621668c2f7c46dec7692e60c03/d1af7/homematic_11.avif 460w", "/en/static/47ccb4621668c2f7c46dec7692e60c03/6e770/homematic_11.avif 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/47ccb4621668c2f7c46dec7692e60c03/a0b58/homematic_11.webp 230w", "/en/static/47ccb4621668c2f7c46dec7692e60c03/bc10c/homematic_11.webp 460w", "/en/static/47ccb4621668c2f7c46dec7692e60c03/632b0/homematic_11.webp 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47ccb4621668c2f7c46dec7692e60c03/81c8e/homematic_11.png 230w", "/en/static/47ccb4621668c2f7c46dec7692e60c03/08a84/homematic_11.png 460w", "/en/static/47ccb4621668c2f7c46dec7692e60c03/4255a/homematic_11.png 915w"],
              "sizes": "(max-width: 915px) 100vw, 915px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/47ccb4621668c2f7c46dec7692e60c03/4255a/homematic_11.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`OK`}</strong>{` to save the program and you are done. The script will trigger every morning at 8:30 am and activate the alarm on your camera. At 6:30 pm the ELSE condition will become true and turn off the alarm before you arrive back home!`}</p>
    <p><strong parentName="p">{`Addition`}</strong>{`: If you have more then one camera you might want to activate and deactivate them all at the same time. In this case just add more commands to the script that you want to execute every time it is triggered:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`WriteLine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Motion Detection Armed"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.114/param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.115/param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.116/param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.117/param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{` that this is only one example how to automate your camera function. Every other function in your camera can be time controlled by the Homematic CCU3 in the same way - you just have to switch out the CGI Command with another command from our `}<a parentName="p" {...{
          "href": "/en/Advanced_User/CGI_Commands/"
        }}>{`CGI Documentation`}</a>{`.`}</p>
    </blockquote>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "using-the-homematic-ccu3-as-alarmserver-for-our-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-the-homematic-ccu3-as-alarmserver-for-our-camera",
        "aria-label": "using the homematic ccu3 as alarmserver for our camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using the Homematic CCU3 as Alarmserver for our Camera`}</h2>
    <p>{`We now have a way to schedule CGI commands to automate our camera from the Homematic user interface. In this part we now want to take a look at how we can notify the Homematic CCU3 when our camera detects motion using the internal PIR sensor.`}</p>
    <p>{`The problem that we will solve is that it is often desired to keep the IR night vision LEDs switched off until the PIR motion detector is tripped. This reduces the visibility of our camera at night as well as our electricity bill and most important, we no longer attract flying insects at night, that in turn attract spiders that build their nets in front of our camera lense.`}</p>
    <p>{`All INSTAR HD and Full HD cameras can send an HTTP GET request to an external alarm server when an alarm is triggered by the internal PIR sensor. We can create a `}<strong parentName="p">{`Webhook`}</strong>{` on CCU3 that can catch such an request and triggers a `}<strong parentName="p">{`System Variable`}</strong>{`. After that we just have to write a small program that sends an CGI command to our camera that switches on the IR LEDs for a minute.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "setting-up-a-system-variable",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#setting-up-a-system-variable",
        "aria-label": "setting up a system variable permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up a System Variable`}</h3>
    <p>{`To create a new System Variable go to `}<em parentName="p">{`Settings/Sytem Variable`}</em>{` and click on `}<strong parentName="p">{`NEW`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "822px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/95cffaee75d85dd6708b0218c32e16d4/f73a1/homematic_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADeUlEQVQozyWPzU/bZgCH/RdMO7RsO+2wHWCdVKm9TNqpUqtt2qTtUNRO07RKVNMkIDugtoJSkqZ8NGk+25CQDz6SJiENCSmItgxIgJYlaQiN7cR2IH7t+PVrx04IWzppU3thojzHn/RIzw/7+GzfmfPaL78baevQnD43dPaC9mR77/ufdH96oVtrmr5nDfZrHf26B7eG3Q733ODwVN9Nd1eP4YbWfbnLjJ37Xnepy/zTr9Yfr5p//s3Wc91z8Yrx/A/6i5rbvmAkHI7ZHV6r3e32+sfM/s4rd7/p1H97Sf915+0vvrqJWRxhmzM0Zplx+qIuX8TmCttcYddkZNwTNdkDxvsBqzMUiswlFh4P3HFZJ6KpzY1xT8QyHtIbA5g/GteabKP3ndf0Y9eHDQOjxiGjZfCu2eKb0ZntA6NGy4Qnh+Mckmv7auPvgxxZGnngsk76Y0tPsUw2m9rYWHzyNBCNBWPzc4mF1Mb6/OLidORROBZPppJPlv9YXkuSTBnV92v7B2R5dyo0O5tYeLaWxCRJUlUVIYREKApQkWVFUSSEdhmG5/i3b99wPP88k8UpGtaUqiSDqsDQdKlYLDMMBiGsNxocx/E8LwgCy7K1Wq1SqTA0Q9N0o94oUnQynX6Rz2cKeJ6i8xRVokoQQgAAxrIshLBSqbBsBQDA8RwSEccBHMdpij5otcjN5490dyIGU8JsX52cSfsf4rltgiRLFHUsi+9kFnBHIBEBAEiSLDNM86BJ4cTqwuLWWrKYy++RJWp7hygUyu84ylZVFQBwfP44m+d5AADLskcLxyFVzeP41suXUJZ5UWSYsiRJPM8fyUiS+CoviqIsy9VqVVEUQRAIgqhUKq1WCyH0z+vXJEFkM+ndMgOhgOP49vY2WSxiAHAISeXyLs9XRRExTBlCSNM0RVEkWUQQMgwDRTGTySRTKZwgCoVCsVSS5RrH8ZiqyI26KkuiUkOqqkhIbDbrqiIRhR0AhdbhYfOv/cPDN+we/WonW1fQf/+22D26RL4CLIOtJLMv0oVnq+mV9dzmnzsrqcxKKreSTC9v5LbiS1LgYSy8FJzfHPfGbc6IazLhmnrsDSyF4+vBuSR2TTtttIV0hlDfoO/GkOPWiE/T7zXZowbPwsQv/bMnTp9pv/peu+ajU5oPP//9g1Oats962zp6T7R3n+zo+R8WU4b7i9HBagAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95cffaee75d85dd6708b0218c32e16d4/e4706/homematic_12.avif 230w", "/en/static/95cffaee75d85dd6708b0218c32e16d4/d1af7/homematic_12.avif 460w", "/en/static/95cffaee75d85dd6708b0218c32e16d4/eac84/homematic_12.avif 822w"],
              "sizes": "(max-width: 822px) 100vw, 822px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/95cffaee75d85dd6708b0218c32e16d4/a0b58/homematic_12.webp 230w", "/en/static/95cffaee75d85dd6708b0218c32e16d4/bc10c/homematic_12.webp 460w", "/en/static/95cffaee75d85dd6708b0218c32e16d4/355f1/homematic_12.webp 822w"],
              "sizes": "(max-width: 822px) 100vw, 822px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95cffaee75d85dd6708b0218c32e16d4/81c8e/homematic_12.png 230w", "/en/static/95cffaee75d85dd6708b0218c32e16d4/08a84/homematic_12.png 460w", "/en/static/95cffaee75d85dd6708b0218c32e16d4/f73a1/homematic_12.png 822w"],
              "sizes": "(max-width: 822px) 100vw, 822px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/95cffaee75d85dd6708b0218c32e16d4/f73a1/homematic_12.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I will call the variable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`motion_detected_8015`}</code>{` which is of type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Alarm`}</code>{` and should be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{` once triggered. Click on `}<strong parentName="p">{`OK`}</strong>{` to save the variable.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "902px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/58213/homematic_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA50lEQVQI1yXCy06DQBQAUP4/roxFQpRnGl4L/QeTFnXATqG0DGOrhblja8MjDLaycuHJka7Vu4mmTzT9SlZUe2r5gekFsmHpjmu4nmyYqj01XP/WsBTTtvxAMS3N8ZyHx5t7XQpRtC5oRkgYxXiVbQqaU4oWeJGu8vftcxSHr+hpHr4labxMNgVFeLn93OeUzl6QBAyGYRC9OB6/hRCi70Xf13V9Op3GcQQGXdsyxrqua5rm53xu2/b3cvnY7bJsLaEomoXznBCMcZKmDODrcGAAwDlwvi9L4Lysqv/AecVYWVaEkJyQP/YSwyHTj+3gAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/e4706/homematic_13.avif 230w", "/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/d1af7/homematic_13.avif 460w", "/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/4d463/homematic_13.avif 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/a0b58/homematic_13.webp 230w", "/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/bc10c/homematic_13.webp 460w", "/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/5cb76/homematic_13.webp 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/81c8e/homematic_13.png 230w", "/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/08a84/homematic_13.png 460w", "/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/58213/homematic_13.png 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1a12d48ff4e11c8b5ed1ab591c11f6de/58213/homematic_13.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "configuring-the-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configuring-the-alarmserver",
        "aria-label": "configuring the alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring the Alarmserver`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Please note`}</strong>{` that there is a new `}<a parentName="p" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing/"
        }}>{`Alarm Server API for WQHD 2K+ cameras`}</a>{`! The individual steps below are still valid. Only the query is now called `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&trigger`}</code>{` instead of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&active`}</code>{` and the numerical values have changed and now allow the evaluation of multiple, simultaneous alarm triggers!`}</p>
    </blockquote>
    <p>{`The Homematic Software now offers us a `}<strong parentName="p">{`Webhook`}</strong>{` that allows us to trigger the variable by sending a web request to the CCU3 Server. For example if your Homematic Server IP is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.14`}</code>{` (please change this address to your own server address) send the following request on `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`port 8181`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://192.168.2.14:8181/ccu3.exe?Antwort`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`dom.GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"motion_detected_8015"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`.State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ccu3.exe`}</code>{` can be called whatever you want - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`doesntmatter.exe`}</code>{`. We then have to specify what variable we want to trigger `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`motion_detected_8015`}</code>{` and what state we want to put it in - when creating the variable we choose that `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` means that the variable is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`true`}</code>{`, so we have to choose `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`State(1)`}</code>{` here.`}</p>
    <p>{`Copy and paste this command into your browsers address bar and type in your Homematic user login - sending the request will trigger our variable:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "902px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cd25f73616c94d0a152fef7d0002100f/58213/homematic_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAA7CAAAOwgEVKEqAAAAC8klEQVQoz32R60+TdxTHnz9hL+bbJSYz7uV0CV42Il6hhRYXBlMLLc7FmanJnGSvli1eIgGrjtssFHuBtkNCNke0I9yq0GUGopHSp8W4TFqpYEq9lD4P7Wyfz9KnU19tv+ST8/2ek/M7v5yfsPaDBjZrzrBx5/e8s+Gkqt8t+oY17x3n7fXHeGvdUbaUfMWlNg/mFiffnWnnXPNlumxXOd/i5osTbRgON3K0oQ29oQlBW3MKw2Eznx9vwXjkAsYjZvYdaqba1EiVqZG9hrPUnzjLxXYnP7ReofmChU/qTvNx7TmqTE3o959Gv/8UpVXf8v62rxGGfbe55vXxq9fHjZEJbgzfUr13ZIKhMT9DY5NcH5rg6i+jDAyOq3TYf8N8eZAOuxfv6CT+P+4ydUdkfPIegn96mlaHE4vLTafbw49uD63OHszWbtp7XDRZujBbrVy6YqOj10Wr3YG1z0Onu5c2h50mSyeBuTleHcE/NU23pw9n/wA9Az/TZXPgcvfh8//O8JiP0Zu3cPQPYOlx0W5z0n9tkJHxm1wfGsb2Uz8djl4CobB6WTqTQVh58ZxoJELs0SMWluNI3XZSVhvPZInniQRPl5dJxOPEnzzhfjjE00SCZ4kEUirFQjRKKBhEluU3L9xXfwjjwc8wmkwY6w9iqK1Dr69EU6GjrLyCMm05pf+yu0xDqaZczed9mbZC1ZWVe6mp+ZTq6hqEbaVa9mh1aCr07NLq0O3R8GXJLuqLt3OgaCuGTR++pnbzRwVd9EbXbdpK8cYidmt1FJfsQJgLhwnMziKKIjMzAYIzAaJiiIgY4q/ALA9ng8wHReZFkUgo9NpHQ2FVLz74k8VYjNjjxywuLSGkU6sk40mklMTfqxlyuRxZIKsoZFEK8X94qSjklJzaBwpCJp0hcn+e2MMFkisr6oLzC1+VZWRJLnhJIplMIkuSilpT8ym1lieTyRQ+JS2liS/ESSwtI72QyL7MqhOVnKJOVZRCfMV/+XzM8w9+btpM4OWeegAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd25f73616c94d0a152fef7d0002100f/e4706/homematic_14.avif 230w", "/en/static/cd25f73616c94d0a152fef7d0002100f/d1af7/homematic_14.avif 460w", "/en/static/cd25f73616c94d0a152fef7d0002100f/4d463/homematic_14.avif 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cd25f73616c94d0a152fef7d0002100f/a0b58/homematic_14.webp 230w", "/en/static/cd25f73616c94d0a152fef7d0002100f/bc10c/homematic_14.webp 460w", "/en/static/cd25f73616c94d0a152fef7d0002100f/5cb76/homematic_14.webp 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd25f73616c94d0a152fef7d0002100f/81c8e/homematic_14.png 230w", "/en/static/cd25f73616c94d0a152fef7d0002100f/08a84/homematic_14.png 460w", "/en/static/cd25f73616c94d0a152fef7d0002100f/58213/homematic_14.png 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cd25f73616c94d0a152fef7d0002100f/58213/homematic_14.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Success! We tested the concept - now we can add this Webhook to our cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarmserver`}</a>{` configuration so that the camera will start tripping our CCU3 every time the internal alarm is triggered:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/caa21e5ef26d9fb24373227518738bac/681f1/homematic_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC4UlEQVQoz12S22/SUBzHi4oxMMNctsTbqgwdBVoS3cJGO9rTntKWAh20pS2VU3YxvumccxHQ7cFkcRqvD2abTy5e3oyJiT5q5v4xQ2GX+MnJyXn55Pv7/c4PS6fTVDpNjMdE1NHXvqurn+SVT3BpF97f7d/+Q7i3qyx/rK1/m7EfXr584WosNjY2hpEkSVJU/HqsONea2/jurn921r4anS//n/YX+8nnOy9+cPZycigyefHSyPnzXTlFkgkiLohS7elPc3PPevYbvdpbfLc//+bvwtv9hbd/Gy/37M0/9uaeu/ErfnOycOqkNzCAnTmDURRF+jKUVfToPep8QO1t1NpCra1Gaxu1t7z2ttfZQe0dr73TXPt4g2bHMIwMBIKh0IGcSIgiRHXTtaqOWamUlEpJUWVBlQRFBJLAypDN87my7uRYMDg8HD43GA6HsVQqRZIkQRCiKDbn5uvuLcuydcMwzJqiFAQIZ3IszTA0w0xnaSgIE5MTmE8oFMISicSh7HmeZVnValXXu7okSQCAbDZL0zTDMHQ2C6AgZempwSHqbGQgFMKSyeRB2V0ZIWTbdrVaNQxDURQIYS6XAwDkfQqqymQyI8HgUPB0+FA+TG40Godyz2RZlukxw0AIJzMZLBA4EQxGIpGunEql+j03mwgh13V7lc/OzmqaVi6XSz6aps01EQDcFRzvrkg02h0YRVEEQUAI6/V6rVbTDzC6YzvCME138S6AeXx01Hej/pIcJHued1wuqmpeFAWe7x0eAFmd5YCA48fko69qNh3HMU2zFwslmRXyNMtPMWyWzk1PMwDwHMfhON6XUz7xeBxC6Lqu4ziWZflF66WqWdQ0Wc5LkghKGq2WBd3ieP4oOeEzPj4OIexrvW4N09Qr5sIDd+WNufT8UcV6rVWeVfQ8AKNHySSVJNNEkuQAr2lasVQq9impakG1b2uLjwvN1TtyuSXKK7LK0TQejV1PpK9di/0DkwcJhSsLahQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/caa21e5ef26d9fb24373227518738bac/e4706/homematic_15.avif 230w", "/en/static/caa21e5ef26d9fb24373227518738bac/d1af7/homematic_15.avif 460w", "/en/static/caa21e5ef26d9fb24373227518738bac/06569/homematic_15.avif 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/caa21e5ef26d9fb24373227518738bac/a0b58/homematic_15.webp 230w", "/en/static/caa21e5ef26d9fb24373227518738bac/bc10c/homematic_15.webp 460w", "/en/static/caa21e5ef26d9fb24373227518738bac/8bcb7/homematic_15.webp 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/caa21e5ef26d9fb24373227518738bac/81c8e/homematic_15.png 230w", "/en/static/caa21e5ef26d9fb24373227518738bac/08a84/homematic_15.png 460w", "/en/static/caa21e5ef26d9fb24373227518738bac/681f1/homematic_15.png 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/caa21e5ef26d9fb24373227518738bac/681f1/homematic_15.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Don't forget to activate the alarm server and add your Homematic authentication so that your camera can contact the CCU3.`}</p>
    <p>{`Now we can switch to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`Alarm Settings`}</a>{` and activate the PIR motion detection (make sure that you deactivated all `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Motion Detection Areas`}</a>{` as well as the coupling of the PIR sensor and detection areas):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "898px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/32a8be52e78f09e640ca0bdcebcccec1/84cc5/homematic_16.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACnElEQVQoz03RSWtTXRwG8IALTdRoqeK00PjSNjet1GoSRM3QxGa4ufec8z/zdKuCFReCC02dcYIuXnhdvG5MFXHXhYqKuNDvIPiR5BotfXg4HA78OFNmrlQ8c4GyJ1/i+x/Jw0/h3Y9Lqx+Whu/TcTzZ0s7qe/boE3/8eWrh/D+Fo5mgOH1mMUwevDG3193dV+r2uhiOxHBEb43YrZEYrvPhiKcr6787MndGl+6/XpiZ379/MlMqlU6fWhAQKRqTsANRF6N+FLZ7jUrYPqdobDgWZEAGHRr3UL+91Krxbrs5sXfbjh0prlQq2hjrnLGOceHjyKrLau07v/FcS+78snGeMs64wIT0wwFgMjUxsT2bTXG5XDbGJElinROcb5yuvFiM8do3dv1fTrHznjFGt4QxduDIkZ25XCYIgnK5bK1NksQ5J6RklJI4ajXOdi+0jLHWWmOMNUYwhhHuh2GMUD6fz2azmZmZmWq1qpTSWiulxpsQAlGM4xgJwa21zlqpdcwYAQAcc4CTBw/vzuVSXKlUxsf23qeeMoxQu9XsdTta67HUBL6eOPk01PHDDXHlzqH8rtwmllKq3+FcUABCBbirWHrBuXNOac0Ahp3exQHqsgSBnNw3mc/vSfHmnb33UmkGCNyK+/+nWB0pDtY6rTVlLBJigFHY68TRYG52tlAo/HkwY4z3PvVJwgBRtyKe/4CbLyXDyfKylJJSyoFSACEkpSwIghRvftUYO+8picFcFmvf6Y3/AA0IAYIRwQiAYAqYAqG0NMZBEFSrVSml+BuZRkltpTJSMOku2Xtv3LN3K9eevg2jDcwfIJiamz1+7FimWCzOz88vbkmz2azX6416rT5uo9nsomYfWu0eOl8jtUa3Vpuanj5eKPwCy6oG1wWElMMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/32a8be52e78f09e640ca0bdcebcccec1/e4706/homematic_16.avif 230w", "/en/static/32a8be52e78f09e640ca0bdcebcccec1/d1af7/homematic_16.avif 460w", "/en/static/32a8be52e78f09e640ca0bdcebcccec1/f430a/homematic_16.avif 898w"],
              "sizes": "(max-width: 898px) 100vw, 898px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/32a8be52e78f09e640ca0bdcebcccec1/a0b58/homematic_16.webp 230w", "/en/static/32a8be52e78f09e640ca0bdcebcccec1/bc10c/homematic_16.webp 460w", "/en/static/32a8be52e78f09e640ca0bdcebcccec1/005c4/homematic_16.webp 898w"],
              "sizes": "(max-width: 898px) 100vw, 898px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/32a8be52e78f09e640ca0bdcebcccec1/81c8e/homematic_16.png 230w", "/en/static/32a8be52e78f09e640ca0bdcebcccec1/08a84/homematic_16.png 460w", "/en/static/32a8be52e78f09e640ca0bdcebcccec1/84cc5/homematic_16.png 898w"],
              "sizes": "(max-width: 898px) 100vw, 898px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/32a8be52e78f09e640ca0bdcebcccec1/84cc5/homematic_16.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can try your settings - walk in front of your camera and see if the system variable is triggered:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4ba685826f5f17886da1500f1fad5eac/681f1/homematic_17.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABtklEQVQY0wGrAVT+ADdIgys9exwvcThJgyQ2dhQobRQnbBAmbB8ramlrjI+WqXqAlXuBlIWJmpabqoGIoXJ6mWhwjXd/mVxokABVYItbZ49SXopUYItRXYpGU4JCUH9ATYBFV3uCj5aio62Zmp+HiZddZoR0fJdfaY1FUX5FUn1CT3w0Q3YAmZ+vnKGwoaayn6OwmJ+voqezpKm0pqu2oqezmJ6uk5qsnqOwj5ataHagZXOdd4Kkpam1o6i2oKa1lZuwAKeytKu1uKu2uKu2uKu2t5ypq6Ovsp6qrqCsrqm0tau1t6u1t6Wxsqu2tKm0sqy2tqOvsd/i5P////7+/gCMmpySn6KToKOIlpmNm52hq6yjq62mr7CPm52CkZSCkZSFlJaep6mjrK6ZoqSIlpmCkpXU2Nn////8/P0Ax8rKys3NyszM1tjZ2Nna3dzc3Nzb397d1dXWz9HSwsTEw8TE3t3d29va3t7ex8jJwMLC6uvr/////v7+AOTj5Obm5uTk5O/v7+7t7efn5+rq6ujo6Ofn5+vq6uHh4N/f3+vr6+fn5+rq6uDf39zb2/Pz8////////zYgE+2gU3nEAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ba685826f5f17886da1500f1fad5eac/e4706/homematic_17.avif 230w", "/en/static/4ba685826f5f17886da1500f1fad5eac/d1af7/homematic_17.avif 460w", "/en/static/4ba685826f5f17886da1500f1fad5eac/06569/homematic_17.avif 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4ba685826f5f17886da1500f1fad5eac/a0b58/homematic_17.webp 230w", "/en/static/4ba685826f5f17886da1500f1fad5eac/bc10c/homematic_17.webp 460w", "/en/static/4ba685826f5f17886da1500f1fad5eac/8bcb7/homematic_17.webp 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4ba685826f5f17886da1500f1fad5eac/81c8e/homematic_17.png 230w", "/en/static/4ba685826f5f17886da1500f1fad5eac/08a84/homematic_17.png 460w", "/en/static/4ba685826f5f17886da1500f1fad5eac/681f1/homematic_17.png 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4ba685826f5f17886da1500f1fad5eac/681f1/homematic_17.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The variable was triggered a second time - this time by our camera!`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "writing-the-program",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#writing-the-program",
        "aria-label": "writing the program permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Writing the Program`}</h3>
    <p>{`Just as before we can now write a little program that is triggered by the state change of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`motion_detected_8015`}</code>{` system variable. First go back to the `}<strong parentName="p">{`Program Menu`}</strong>{` and select to create a `}<strong parentName="p">{`NEW`}</strong>{` program:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "901px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6ac3f81be121f66ef01bfda0e7b97dee/0955e/homematic_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAADNUlEQVQoz6VQXU8TaRSef+Dl3qo3qxdeGNesGr3yRrPrxnjBxi9M/MBqdo0SImqoVD6K5WNKCrXFtrZSaSG1IFBb2d2SILaIOnTa6cx0Wun7TjvM+0I7IPGWDmaqa7w1PjnnJOfJeXKec4htext+PdKy+7B+6576g7+37Tp0e8f+xp92/rVlu27foWsdnZ72TtftZovBaO2zDXWQg1cb+s/pOq7dtJyoNRG/1bTUXu45/3ff6TpSV2+9cN16qd56+kpvzcUeXVO3xx98+Gio1+KorTPWXu46qyP/ONV2/EzrsZOtew83El32Ub15hLR57Y/8FseQp98x8cA64bCP26z/DT6JuL3uB27S7qtvdtY1WK/rB+zuEedgwOry29xjxI2B+aP3Zk0Or8Pnuz/g6DKajHfumgxt7U3N7XpD6607ZDfZ5/WanQ9Ntv5et4t0uc0uTxNpDkWjxKv43MzLWCw+93p+fjwUHpkIjUYio+EXwefh0XDEN/YsGI4EJ0KB8ZB3OOAcHPL4hv3BscD45Nzbd4QgZEQRyrJcVsr5xUW4mAf5/OL7XE4QpGKxIMJVpVxaWcEY8Rz37z9Tc/E4m04jWV5VFILneY7jQB4ghDiO4zN8JpPheT4jCAhjJpWKxWKJRKJQKNA0HYmEZ17O0AkaAIAwIgRBgABCCBFCKSaVSqXS6TTDMIIgSJLEsulkKsnzHAB5mqaj0Wg8HqdpTYwxJgAAAAJRFDHGNJ2gKCqZTGaz2VwuByGsWsiwHMtz3MLCwtTUi9nZWU0M4fLyMsGxrCBkIdTOZhiGTbOaeZ5nWQ4AQC1Q82/e8BwnQviOoiYnJ6enpymK0mwjROBlXKpCURRZlhFCCFcLxmtra5Ikab8sl0ulUrFYzOVymtHqjevr68TmD4CoVNSKqlZTVdVNrf0/NPoLvjAblcpG5SunfrN5Y6MCxU1V/Y7NMl5bQoq8+hExWaXmrJyXsPIRlz6glXVJLi9hBZc+SEiRkKKN4dXCUlmUSp+DaCeH7a5gV1/A1OMPmF3dvSMG0+NOs7ut29dB+jrNHn2rjbQ8Mdz3tpg8NufTI38at//SsONA48/7Gz8BtRreNw1WfUkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ac3f81be121f66ef01bfda0e7b97dee/e4706/homematic_18.avif 230w", "/en/static/6ac3f81be121f66ef01bfda0e7b97dee/d1af7/homematic_18.avif 460w", "/en/static/6ac3f81be121f66ef01bfda0e7b97dee/81e7a/homematic_18.avif 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6ac3f81be121f66ef01bfda0e7b97dee/a0b58/homematic_18.webp 230w", "/en/static/6ac3f81be121f66ef01bfda0e7b97dee/bc10c/homematic_18.webp 460w", "/en/static/6ac3f81be121f66ef01bfda0e7b97dee/2b666/homematic_18.webp 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6ac3f81be121f66ef01bfda0e7b97dee/81c8e/homematic_18.png 230w", "/en/static/6ac3f81be121f66ef01bfda0e7b97dee/08a84/homematic_18.png 460w", "/en/static/6ac3f81be121f66ef01bfda0e7b97dee/0955e/homematic_18.png 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6ac3f81be121f66ef01bfda0e7b97dee/0955e/homematic_18.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The script that we need should activate the IR LEDs of our camera and - after a delay of 60s - deactivate them again. This can be done with the following two scripts:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`WriteLine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Activate IR Nightvision"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.117/param.cgi?cmd=setinfrared&-infraredstat=auto&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`WriteLine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Deactivate IR Nightvision"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.117/param.cgi?cmd=setinfrared&-infraredstat=close&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "902px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6dacc090c0b538d85b55ab32332ae930/58213/homematic_19.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB1UlEQVQozzWOWW/aQACE/e8r5aVJSsBcBnPajvEa8LXeXdvgCy9HAnYJKg9EVUucxP0PFVSVRqPRJ81omKE6aQylhxbfEmW20yvzvUqnz3YHbWnEy0pjKJb5Htsd3DfbbHcgajonSCWOrw0EPA+Y1WYz84M4odl+v6A0CKNokcSU+mFEPC9cLOIkiRM6C4IoSby574dhGMdxkuxfDsxYN7m+MBiBCUQaIhpyDOKZ7mwCkTTRdewYxNUQmVi2G0RwFpAgRn6oYZdunhgV21VxwEmKbCJgO4pNAHJV7CmQyBZWLsSRLTwyke0vULyEEUXR0pjHwXLNiMaE7fYlzdJdH0ACoANsAiBWbQdAR7WdayCq7SgWViEBFgKQTB0/Xj8x7b7w5dvDTblaqnP39dZdtXFbq3+t1Cqtzl21ecvWS81WiePvG9xNqVznh5w05uWxoJnzBWWAMRV0U5iaIwsDeJlXrieBTRRIlAskwCaPhi3plmjgoY4eTSzq1qUMPQK9OQ6jMXJmyXL7clhuU/qcrtKMbtN1tqfb3SrNVtn35Xa32qXrNEt/HE9v78fTK/N82B1fTz/P5195fv74yIsiL4r34s8/5Rcv8mv4ryL/LN4+i995/hdPJo2FtHVzPwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6dacc090c0b538d85b55ab32332ae930/e4706/homematic_19.avif 230w", "/en/static/6dacc090c0b538d85b55ab32332ae930/d1af7/homematic_19.avif 460w", "/en/static/6dacc090c0b538d85b55ab32332ae930/4d463/homematic_19.avif 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6dacc090c0b538d85b55ab32332ae930/a0b58/homematic_19.webp 230w", "/en/static/6dacc090c0b538d85b55ab32332ae930/bc10c/homematic_19.webp 460w", "/en/static/6dacc090c0b538d85b55ab32332ae930/5cb76/homematic_19.webp 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6dacc090c0b538d85b55ab32332ae930/81c8e/homematic_19.png 230w", "/en/static/6dacc090c0b538d85b55ab32332ae930/08a84/homematic_19.png 460w", "/en/static/6dacc090c0b538d85b55ab32332ae930/58213/homematic_19.png 902w"],
              "sizes": "(max-width: 902px) 100vw, 902px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6dacc090c0b538d85b55ab32332ae930/58213/homematic_19.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`OK`}</strong>{` to save the program. When you now walk in front of your camera again the system variable will be triggered and your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/IR_LEDs/"
      }}>{`nightvision IR LEDs`}</a>{` will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`switched to automatic`}</code>{` (they will only be switched on when it is dark). After a minute they will switch back to their deactivated state:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "901px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/0955e/homematic_20.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACd0lEQVQoz12R20/UQBTGy5rloiAa8EGybJcugW235cVNjCag3bW722kp07n0NrOFVbl4iSQaoxEVJNEHjZqYEIyJ4ckYXry9yaN/mdl2QcMvZ07m5ZvznW+EmZkZVdUUeVpf3DLv7zXufdTv7F5Z2zlWc6s71ds7zsO9q/zpuJjPF4uTk5NCKpZLU3D9Q/vNQevlr2D7F936cVTkxc9O3/webn9be39g330nnRut5HLnx8aEcoKsKKBptCKPBZQFFC1YrgNcByAH+HYTO8BdsKFjEQQrly9Wstn1s2d6sllB07SOWJaBZbXiOAhChDHqHAwxiSD6eqHyIH6C3/5pvz4IXv2+1IB5QagKPSd6ewU1QVEUAEAcx77v4wSUgBGOHIveeOw/329tfuFb+4aFRkdHhkdGBgYGBFmWNU3rTAaAcx5FEWPM8zx0CEwahvPugr1ys63rupDJCJlMb19fR6yqaqlUAgAsLS21Ev4XI+RS6gVB6Hl+ELFmtVY6NTR1cmiwv78jLpfLiqKYpkkpRQgRQjDGruvCBDeh84brUt9vVmvy0PD04OmuWFXV1Ha73eacs0N4wtGdMRZF0ezcnJDQd2RblmXLspaXlznnvu97nkcTwjCEEBJCoijyKKGUGoYxnsuJ+bwoit3A0rQZY0ECISS1HTHWihd5K+aMe2FMkFs3jEKhIElSsVj8l7ZpmowxQki6Z/erXAdDm0AbehSw624Y1hsNURQnJiYkSToeGMaYdKEEQbyywTf28KNPz1z22XZ25xEyjPGCKKViTdPSnXVdtyzLNM1ms9lIqRsNGILgVt1fjerWWu3aitGozc7mC4V08l+25+8WDCOpmQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/e4706/homematic_20.avif 230w", "/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/d1af7/homematic_20.avif 460w", "/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/81e7a/homematic_20.avif 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/a0b58/homematic_20.webp 230w", "/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/bc10c/homematic_20.webp 460w", "/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/2b666/homematic_20.webp 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/81c8e/homematic_20.png 230w", "/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/08a84/homematic_20.png 460w", "/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/0955e/homematic_20.png 901w"],
              "sizes": "(max-width: 901px) 100vw, 901px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c668d2d74c79feef9b2cc8b02a6aaa5a/0955e/homematic_20.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "connecting-our-camera-to-an-homematic-pir-sensor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connecting-our-camera-to-an-homematic-pir-sensor",
        "aria-label": "connecting our camera to an homematic pir sensor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting our Camera to an Homematic PIR Sensor`}</h2>
    <p>{`In an earlier tutorial `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Homematic_CCU3_and_RedMatic/#add-sensors-to-the-control-panel"
      }}>{`we already added the Homematic PIR Sensor`}</a>{` to our CCU3 and `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Homematic_CCU3_and_RedMatic/#creating-system-variables-and-programs"
      }}>{`we created a System Variable that is triggered when the sensor detects motion`}</a>{`. We then installed RedMatic - a Node-RED addon for Homematic - to connect our camera to this System Variable. This time we will do it without the help of Node-RED.`}</p>
    <p>{`Here we want to use the input from this external PIR sensor to have our camera pan to a different position. Our camera is watching the left while the PIR sensor keeps an eye on the right, eliminating the need for a secondary camera to cover the second angle.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Homematic_CCU3_and_RedMatic/#creating-system-variables-and-programs"
      }}>{`Program that we created`}</a>{` uses the output of the Homematic PIR Sensor (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`HM-Sen-MDIR-O-2 NEQ1545018:1`}</code>{`) to switch the System Variable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PIR Sensor`}</code>{` to triggered:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "881px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/940516e038dd0996a4b2a5af0c6f6861/96658/homematic_21.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABkUlEQVQY0zXG2Y6bMABAUf7/rVU1KM3ClB1DWGyzr8aQDRwmUfvSpJ0os+al/YUqGVW6ujrcRNXGivYNzL/M7keyeifKEw0IwBR0wEsKLyl3ovxpIgiGKVrzqQ4EYH2eClMdjBWNqwjZ7feH47Hr2fCwY2yoKW3aRd20NW2Gh/2mYz1jbLutKkJqul5vNn3f9WyxWnEzRfsqylNZ1T00D+J5ENthYuHQiVLLjwHyDegrDgQoMODVmg2DtAxJa+GQk7Ez0lXZhm5WOknhpqWXVk5SwJzgkuKKorJ2swqVFJUUFrWTFmmzzFfMCmJupEq8IGqeb0cZwJGJQzOIAQ6BHwMcGSg0cXRDcLUfm0GiuthNS4ACjpcl2fZEyxUt5x7YY1XnJWWsGjPNtFEIcwKzyssqLy29rEIFgTlxkyJuVl5acKbvoayAWWGgIF8s18OOdqzpWNP3TcfafqDrD2zppqfdteWw+3783fZbjnTtj8Ph1/np5+Pp9PL6fLmc396f3m/dcH59+8D1/3H58/f4ePoHVptgTNHErMQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/940516e038dd0996a4b2a5af0c6f6861/e4706/homematic_21.avif 230w", "/en/static/940516e038dd0996a4b2a5af0c6f6861/d1af7/homematic_21.avif 460w", "/en/static/940516e038dd0996a4b2a5af0c6f6861/5334c/homematic_21.avif 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/940516e038dd0996a4b2a5af0c6f6861/a0b58/homematic_21.webp 230w", "/en/static/940516e038dd0996a4b2a5af0c6f6861/bc10c/homematic_21.webp 460w", "/en/static/940516e038dd0996a4b2a5af0c6f6861/8e8eb/homematic_21.webp 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/940516e038dd0996a4b2a5af0c6f6861/81c8e/homematic_21.png 230w", "/en/static/940516e038dd0996a4b2a5af0c6f6861/08a84/homematic_21.png 460w", "/en/static/940516e038dd0996a4b2a5af0c6f6861/96658/homematic_21.png 881w"],
              "sizes": "(max-width: 881px) 100vw, 881px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/940516e038dd0996a4b2a5af0c6f6861/96658/homematic_21.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We now need a second program that uses the the state of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PIR Sensor`}</code>{` variable to send commands to our camera. So go to the `}<strong parentName="p">{`Program Menu`}</strong>{` and click `}<strong parentName="p">{`NEW`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "899px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/681f1/homematic_22.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABvklEQVQozz3H3Y6aQACGYe6+B3vQZhe1Kj+CKCACDg4MDMOgKP8sqxJFt72Yxpg0efLm+xjFtH8rKqfqP6c8p64kw3znRU7VZ4Yp6MaHMBtKc3lt85oh6AY7k9mZLK7WrDRHdMdcuq6s66ppqrq59n3/eDRte7s/bvdH//g+XS7nrrvd7931eu37pm0/v05lXWdF8f3nLwM8NF+Zmr2xPORGsUd3DiYgCCGhkFAnIAAF9JDGeRnnZZQWNCvivCTHvD6dGTvEI1URddP0yQZTQHYWpmsUWkFkYWoFkeHhYJ/hrMZp9RJmlZfkx/qT0SAYycoK+ttob6LQRKHlh5ZP7CAyffIahocNN1h72PKJibCJsI3psWoYcaH/YEdvownLiQNBHgryByeO5cX7hP+YCgNe+jXmWE4civLbcDzgJclwFID8Q1G0Z2YBrImqaRZwaeLuDm785O1TSBMYJ2582NJnnSgBJN6QnRUm2zjDWVV+XZiZoY8VdWFvp9pa3yIdoiVwFxuoOe4SQNXxlhuoAu85gKs6rgqgFRBA9oeqYSANAY7CJMNJStIiykpyLKL0ibx6zP/fF5pXUVa13fUfP5yBuLUleLQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/e4706/homematic_22.avif 230w", "/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/d1af7/homematic_22.avif 460w", "/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/06569/homematic_22.avif 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/a0b58/homematic_22.webp 230w", "/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/bc10c/homematic_22.webp 460w", "/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/8bcb7/homematic_22.webp 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/81c8e/homematic_22.png 230w", "/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/08a84/homematic_22.png 460w", "/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/681f1/homematic_22.png 899w"],
              "sizes": "(max-width: 899px) 100vw, 899px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cebfddf0fe9e4e9f4da9a4ee2aa4a049/681f1/homematic_22.png",
              "alt": "Homematic IP",
              "title": "Homematic IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We want to react when the state of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`PIR Sensor`}</code>{` changes to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`triggered`}</code>{`. This should trigger the following script that is going to send the Preset CGI command to our camera to `}<strong parentName="p">{`move to Position 2`}</strong>{` - which will turn our camera around:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`WriteLine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Go to Position 2"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.117/param.cgi?cmd=preset&-act=goto&-number=1&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`We can then add a second script that will, after a short delay, return our camera `}<strong parentName="p">{`back to Position 1`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`WriteLine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Go to Position 1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
dom`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`GetObject`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"CUxD.CUX2801001:1.CMD_EXEC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`State`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"wget -q -O - 'http://192.168.2.117/param.cgi?cmd=preset&-act=goto&-number=0&-usr=admin&-pwd=instar'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><strong parentName="p">{`Note`}</strong>{`: Alternatively, you can also use the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`Camera Park Position`}</a>{` to accomplish the same thing - the camera will always return back to the park position after an adjustable time interval after it was moved.`}</p>
    <ul>
      <li parentName="ul">{`When PIR sensor 1 is triggered look to the left.`}</li>
      <li parentName="ul">{`When PIR sensor 2 is triggered, look to the right.`}</li>
      <li parentName="ul">{`Otherwise look straight ahead (over the parking position)`}</li>
    </ul>
    <p>{`And already you have multiple angles covered for which you would have needed several cameras before.`}</p>
    <p><strong parentName="p">{`Addition`}</strong>{`: Instead of going via the system variable, you can alternatively trigger the sending of the CGI command directly when the PIR sensor receives the message:`}</p>
    <p><img parentName="p" {...{
        "src": "./homematic_23.png",
        "alt": "Homematic IP"
      }}></img></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      